<template>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Zen+Old+Mincho:wght@400;500;600;700;900&display=swap"
        rel="stylesheet"
    />
    <router-view />
</template>

<script setup></script>

<style>
@import "../src/assets/css/reset.css";

@font-face {
    font-family: "YanchaPop";
    src: url("~@/assets/fonts/YanchaPop-Regular.otf") format("opentype"),
        url("~@/assets/fonts/YanchaPopTTF-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.body {
    font-family: "Noto Sans JP", sans-serif;
}

.body.is-Active {
    overflow: hidden;
    /* position: fixed; */
}

@media screen and (min-width: 768px) {
    .body.is-Active {
        overflow: visible;
        position: static;
    }
}

.section_inner {
    padding: 0 8%;
    max-width: 1440px;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .section_inner {
        padding: 0 10%;
    }
}

@media screen and (min-width: 768px) {
    .break {
        display: none;
    }
}

.m_header-space {
    height: 80px;
}

@media screen and (min-width: 768px) {
    .m_header-space {
        height: 120px;
    }
}

.fade-in-element {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: visibility 0s linear 0.5s, opacity 0.5s ease-out,
        transform 0.5s ease-out;
}

.fade-in {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0s;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 アスペクト比の計算結果 */
    position: relative;
    height: 0;
}

.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

:root {
    /* 色管理用の変数 */
    --black: #111116;
    --white: #fafbfd;
    --gray: #999999;
    --primary: #423a45;
    --accent: #f4ba47;
}

:root {
    /* コンテンツ幅管理用の変数 */
    --content-width-sm: 760px;
    --content-width: 960px;
    --content-width-lg: 1088px;
}

:root {
    /* z-index管理用の変数 */
    --z-index-background: -100;
    --z-index-default: 1;
    --z-index-page-top: 50;
    --z-index-header: 100;
    --z-index-menu: 150;
    --z-index-modal: 200;
}

:root {
    --delay: 0;
    --duration: 800ms;
    --iterations: 1;
}

/* ----- */

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
</style>
