import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "index",
        component: () =>
            import(/* webpackChunkName: "index" */ "../views/IndexView.vue"),
    },
    {
        path: "/news",
        name: "news",
        component: () =>
            import(/* webpackChunkName: "news" */ "../views/NewsView.vue"),
    },
    {
        path: "/news/:id",
        name: "newsDetail",
        component: () =>
            import(
                /* webpackChunkName: "newsDetail" */ "../views/NewsDetailView.vue"
            ),
    },
    {
        path: "/contact",
        redirect: "/script/mailform/contact/",
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            if (savedPosition) {
                setTimeout(() => {
                    resolve(savedPosition);
                }, 500);
            } else {
                setTimeout(() => {
                    resolve({ top: 0, left: 0 });
                }, 0); // すぐに先頭にスクロール
            }
        });
    },
});

export default router;
